import React from 'react'
import { useNavigate } from 'react-router-dom';

export const NavBar = ({ leftComponent, rightComponent }) => {
  return (
    <>
      <div className='toolbar'>
        <div>
          {leftComponent}
        </div>
        <div>
          {rightComponent}
        </div>
      </div>
    </>
  )
}
