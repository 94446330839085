import React, { Component } from 'react'
import { NavBar } from '../Navigation/NavBar'
import { BottomBar } from '../Styles/BottomBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faUserGroup, faRef, faBackwardresh } from '@fortawesome/free-solid-svg-icons'

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <NavBar
        leftComponent={
          <>
            <button onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faBackward} />
            </button>
          </>
        }
      />
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div>
          <h1>About the Game</h1>
          <p>This game was developed using React JS and Perl by our...</p>
          <p>Innovation Week Q2 team on behalf of Upland Software Inc.</p>
          <p></p>
          <p>Instructions for the game...</p>
          <p>Upon starting the game, the Jigsaw board will automatically scramble the pieces.</p>
          <p>Your job is simple...</p>
          <p>Piece the jigsaw pieces into the correct order as fast as possible.</p>
        </div>
        <BottomBar />
      </div>
    </>
  )

}
