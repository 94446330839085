import React, { Component } from 'react'
import { BottomBar } from '../Styles/BottomBar'

export default class NotFound extends Component {
  render() {
    return (
      <>
        <h3>Sorry the page you are looking for isn't available.</h3>
        <BottomBar />
      </>
    )
  }
}