import React from 'react'

export const BottomBar = () => {
  return (
    <>
      <div style={styleCSS.bgBottomBar}>
        <p style={styleCSS.credit}>Created by Upland Software Innovation Q2 Development Team.</p>
      </div>
    </>
  )
}

const styleCSS = {
  credit: {
    marginLeft: 'auto',
    marginBottom: 0
  },
  bgBottomBar: {
    display: 'flex',
    backgroundColor: '#ffffff55',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: '1rem'
  },
}
