import React from 'react'
import Button from '@mui/material/Button';
import '../Styles/splash.css';
import bgImage from '../../Images/people-together.png';
import bgbarImage from '../../Images/upland-software-logo.png';
import { BottomBar } from '../Styles/BottomBar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faUserGroup, faRefresh } from '@fortawesome/free-solid-svg-icons'

export const Splash = () => {
  let navigate = useNavigate();
  return (
    <>
      <div style={styleCSS.bgTopbar}></div>
      <div className='main' style={styleCSS.bg}>
        <div className='container'>
          <div className='box-container'>
            <h1>WELCOME TO THE I-JIGSAW GAME</h1>
          </div>

        </div>
        <div style={styleCSS.buttons}>
          <button className='bg-btn' onClick={() => navigate('/gameview')}>
            <FontAwesomeIcon icon={faPlay} /> Let's play
          </button>

          <br />
        </div>
      </div>
      < BottomBar />
    </>
  );
}

const styleCSS = {
  bg: {
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat',

  },
  bgTopbar: {
    backgroundImage: `url(${bgbarImage})`,
    backgroundPosition: 'top 50% left 1%',
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    height: '10vh',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30%',
    borderRadius: '15px',
  },
}
